import React from 'react';

export default function ExpertLegalBlurb() {
  return (
    <div className="legal-blurb sage-col-12">
      <p>A note about the directory. Please note that the Experts listed in our directory are</p>
      <p>independent third parties not affiliated with Kajabi. Kajabi does not endorse or</p>
      <p>sponsor any Expert or listing.</p>
    </div>
  );
}
