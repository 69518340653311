import React, {
  useContext,
} from 'react';
import classnames from 'classnames';
import Expert from './Expert';
import Pagination from '../../shared/Pagination';
import WithLoadingEffect from '../../shared/WithLoadingEffect';
import ExpertsDirectoryIndexContext from './ExpertsDirectoryIndexContext';
import scrollToTop from '../../../scripts/scrollToTop';
import ExpertsLegalBlurb from './ExpertsLegalBlurb';

export default function ExpertsList() {
  const PER_PAGE = 60;

  const {
    experts,
    loading,
    pageNumber,
    setPageNumber,
    total,
  } = useContext(ExpertsDirectoryIndexContext);

  return (
    <WithLoadingEffect loading={loading} lines={10}>
      <div className={classnames('sage-row', { 'experts-page__empty': experts.length === 0 })}>
        {experts.length ? (
          <>
            {experts.map((expert) => (
              <div className="sage-col-3">
                <Expert expert={expert} />
              </div>
            ))}
            {total > PER_PAGE && (
            <div className="sage-col-12">
              <Pagination
                currentPage={pageNumber}
                hideCounter
                itemsPerPage={PER_PAGE}
                itemsTotal={total}
                onClickPage={(e) => {
                  setPageNumber(e);
                  scrollToTop();
                }}
              />
            </div>
            )}
          </>
        ) : (
          <>
            <div className="sage-col">
              <img
                alt="No experts match these filters"
                src="/images/svg/no-experts.svg"
              />
            </div>
            <div className="sage-col">
              <header>No results match these filters</header>
              <p>Change your filters to find available Experts.</p>
            </div>
          </>
        )}
        <ExpertsLegalBlurb />
      </div>
    </WithLoadingEffect>
  );
}
